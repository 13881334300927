import { Button, Popconfirm, Table } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IncidentStore } from "../../stores/incident";
import {
  deleteIncident,
  fetchComponents,
  fetchEvents,
  fetchIncident,
} from "./http";
import { Incident, IncidentComponent, IncidentEvent } from "./types";
import { PlusCircleFilled } from "@ant-design/icons";
import { IconsWithLabel } from "./icons";
import { RichTextEditor } from "../../RichTextEditor/richTextEditor";
import { richToPlain } from "./utils";
import { Spinner } from "../../Components/Spinner";

type RouteParams = "component" | "incidentId";
const IncidentDetailRaw: React.FC = () => {
  const navigate = useNavigate();
  const { component: _componentName, incidentId: _incidentId } =
    useParams<RouteParams>();

  const componentName = _componentName;
  const incidentId = _incidentId!;

  const [component, setComponent] = React.useState<IncidentComponent>(
    null as any
  );
  const [incident, setIncident] = React.useState<Incident>(null as any);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState("");

  const [events, setEvents] = React.useState<IncidentEvent[]>([]);

  React.useEffect(() => {
    process();
  }, []);

  async function process() {
    try {
      setIsLoading(true);

      const components = await fetchComponents();
      IncidentStore.setComponents(components);

      const _component = IncidentStore.components.find(
        (c) => c.name === componentName
      );
      if (!_component) {
        throw new Error(`No component with name "${componentName}".`);
      }
      setComponent(_component);

      const incident = await fetchIncident(incidentId);
      setIncident(incident);

      const events = await fetchEvents(incidentId);
      setEvents(events);

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setError(e.message);
    }
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <div className={`flex flex-col gap-2 pl-8`}>
        <div>{error}</div>
        <Link to={`/admin/incidents/component/${componentName}`}>Go Back</Link>
      </div>
    );
  }

  const IncidentTypeIconComponent = IconsWithLabel[incident.type];

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className={"px-8 my-8"}>
          <div className={"mb-2 flex items-center gap-4"}>
            <div className={"text-xl"}>Incident Info</div>
            <Button
              onClick={() =>
                navigate(
                  `/admin/incidents/component/${componentName}/incident/${incidentId}/edit`
                )
              }
              size={"small"}
              type={"primary"}
            >
              Edit
            </Button>
            <Popconfirm
              title={"Are you sure you want to delete this Incident?"}
              onConfirm={async () => {
                await deleteIncident(incidentId);
                navigate(`/admin/incidents/component/${componentName}`);
              }}
            >
              <Button size={"small"} type={"primary"} danger>
                Delete
              </Button>
            </Popconfirm>
          </div>
          <div className={"mb-4 pb-4 flex"}>
            <div className="w-2/12">
              <div className={"text-sm uppercase font-bold mb-2"}>
                Component
              </div>
              <Link
                className={"text-blue-500"}
                to={`/admin/incidents/component/${componentName}`}
              >
                {component.description}
              </Link>
            </div>
            <div className="w-2/12">
              <div className={"text-sm uppercase font-bold mb-2"}>Title</div>
              <div>{incident.title}</div>
            </div>
            <div className="w-2/12">
              <div className={"text-sm uppercase font-bold mb-2"}>Type</div>
              <IncidentTypeIconComponent />
            </div>
            <div className="w-2/12">
              <div className={"text-sm uppercase font-bold mb-2"}>
                Detected Time
              </div>
              <div>{incident.detectedTime.toISOString()}</div>
            </div>
            <div className="w-2/12">
              <div className={"text-sm uppercase font-bold mb-2"}>
                Resolved Time
              </div>
              <div>
                {incident.resolvedTime?.toISOString() || "Not Resolved"}
              </div>
            </div>
          </div>
          <div className="w-full border-b mb-2">
            <div className={"text-sm uppercase font-bold mb-2"}>
              Description
            </div>
            <RichTextEditor formValue={incident.description} readOnly />
          </div>
          <div className={"text-xl mb-2"}>
            Events{" "}
            <Button
              className={"ml-2"}
              size={"small"}
              type={"primary"}
              icon={<PlusCircleFilled />}
              onClick={() => {
                const url = `/admin/incidents/component/${componentName}/incident/${incidentId}/event/new`;
                navigate(url);
              }}
            >
              Add New Event
            </Button>
          </div>
          <Table
            pagination={false}
            size={"small"}
            columns={[
              {
                key: "description",
                dataIndex: "description",
                title: "Description",
                ellipsis: true,
                render: (description: string, event: IncidentEvent) => (
                  <Link
                    className={"text-blue-500 hover:text-blue-600"}
                    to={`/admin/incidents/component/${componentName}/incident/${incidentId}/event/${event.id}`}
                  >
                    {richToPlain(description)}
                  </Link>
                ),
              },
              {
                key: "createdTime",
                dataIndex: "createdTime",
                title: "Created Time",
                render: (_: string, event: IncidentEvent) => {
                  return <div>{event.createdTime.toISOString()}</div>;
                },
              },
            ]}
            dataSource={events}
          />
        </div>
      </div>
    </>
  );
};

export const IncidentDetail = observer(IncidentDetailRaw);
