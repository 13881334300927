import * as React from "react";
import { Button, Tag, Layout, Menu } from "antd";
import { Link, Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { OrgIndex } from "./Org";
import { useMain } from "../stores/main";
import { AclIndex } from "./Acl";
import { AccountIndex } from "./Account";
import { UserIndex } from "./User";
import { CustomLogoIndex } from "./CustomLogo";
import { IncidentsIndex } from "./Incidents";
import { EducationalVideosIndex } from "./EducationalVideos";
import { ExternalSignupIndex } from "./ExternalSignup";
import { colors } from "./colors";
import { TrialDomainsIndex } from "./TrialDomains";
import { PricingCalculatorSubmissionsIndex } from "./PricingCalculatorSubmissions";
import { BillingIndex } from "./Billing";
import { LocationIndex } from "./Location";
import moment from "moment-timezone";
import { ItemType, MenuItemType } from "antd/lib/menu/interface";

const { Header, Sider, Content, Footer } = Layout;

const AdminRouteRaw: React.FC = () => {
  const { pathname } = useLocation();
  const main = useMain();
  const navigate = useNavigate();

  const [utcNow, setUtcNow] = React.useState("");

  React.useEffect(() => {
    moment.tz.setDefault("Etc/UTC");

    const utcNowUpdater = () => {
      const dateFormat = `YYYY-MM-DD HH:mm:ss`; // .SSS for milliseconds
      setUtcNow(moment().format(dateFormat));
    };

    const id = setInterval(utcNowUpdater, 1000);
    return () => clearInterval(id);
  }, []);

  const menuItems: ItemType<MenuItemType>[] = React.useMemo(() => {
    const menuItems: ItemType<MenuItemType>[] = [];
    if (main.isLoggedIn) {
      menuItems.push({ key: "org", label: "Orgs" });
      if (main.user.globalActions.includes("root")) {
        menuItems.push({ key: "account", label: "Accounts" }, { key: "location", label: "Locations" });
      }
      if (main.user.globalActions.includes("root") || main.user.globalActions.includes("manageAcls")) {
        menuItems.push({ key: "acl", label: "ACL" });
      }
      if (main.user.globalActions.includes("root") || main.user.globalActions.includes("manageUsers")) {
        menuItems.push({ key: "user", label: "Users" });
      }
      if (main.user.globalActions.includes("root")) {
        menuItems.push(
          { key: "educational-videos", label: "Videos" },
          { key: "external-signup", label: "External Signup" },
          { key: "trial-domains", label: "Trial Domains" },
          {
            key: "pricing-calculator-submissions",
            label: "Pricing Submissions",
          },
        );
      }
      if (main.user.globalActions.includes("root") || main.user.globalActions.includes("manageIncidents")) {
        menuItems.push({ key: "incidents", label: "Incidents" });
      }
      if (main.user.globalActions.includes("root")) {
        menuItems.push({ key: "billing", label: "Billing" });
        menuItems.push({ key: "custom-logo", label: "Custom Logos" });
      }
    }
    return menuItems;
  }, []);

  function onMenuClick({ key }) {
    navigate(key);
  }

  const [menuActiveKey, setMenuActiveKey] = React.useState("info");
  function updateMenuActiveKey() {
    let _menuActiveKey = "";
    switch (true) {
      case pathname.startsWith("/admin/org"):
        _menuActiveKey = "org";
        break;
      case pathname.startsWith("/admin/acl"):
        _menuActiveKey = "acl";
        break;
      case pathname.startsWith("/admin/account"):
        _menuActiveKey = "account";
        break;
      case pathname.startsWith("/admin/location"):
        _menuActiveKey = "location";
        break;
      case pathname.startsWith("/admin/user"):
        _menuActiveKey = "user";
        break;
      case pathname.startsWith("/admin/educational-videos"):
        _menuActiveKey = "educational-videos";
        break;
      case pathname.startsWith("/admin/external-signup"):
        _menuActiveKey = "external-signup";
        break;
      case pathname.startsWith("/admin/trial-domains"):
        _menuActiveKey = "trial-domains";
        break;
      case pathname.startsWith("/admin/incidents"):
        _menuActiveKey = "incidents";
        break;
      case pathname.startsWith("/admin/pricing-calculator-submissions"):
        _menuActiveKey = "pricing-calculator-submissions";
        break;
      case pathname.startsWith("/admin/billing"):
        _menuActiveKey = "billing";
        break;
      case pathname.startsWith("/admin/custom-logo"):
        _menuActiveKey = "custom-logo";
        break;
      default:
        break;
    }
    setMenuActiveKey(_menuActiveKey);
  }
  React.useEffect(() => {
    updateMenuActiveKey();
  }, [pathname]);

  return (
    <Layout style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Header
        className="flex items-center text-white border-b-2"
        style={{
          backgroundColor: colors.darkBlue,
          borderColor: colors.lightBlue,
        }}
      >
        <div className="flex items-center gap-4">
          <Link to={"/admin"}>
            <img className="h-auto w-32" src={"/resources/logos/controlPlaneLogoWhite.svg"} />
          </Link>
        </div>
        <span className="flex-grow" />
        <div className={`font-bold text-white mr-4`}>UTC {utcNow}</div>
        {main.isLoggedIn ? (
          <>
            <span className="text-white mr-1">{main.user.email}</span>
            {main.user.globalActions.includes("root") ? (
              <Tag color={"black"} className="ml-2">
                Root
              </Tag>
            ) : null}
            {main.user.globalActions.includes("manageAcls") ? (
              <Tag color={"black"} className="ml-2">
                Manage Acls
              </Tag>
            ) : null}
            {main.user.globalActions.includes("manageUsers") ? (
              <Tag color={"black"} className="ml-2">
                Manage Users
              </Tag>
            ) : null}
            <Button type={"link"} className="ml-8" onClick={main.signOut}>
              <span className="text-white">Log Out</span>
            </Button>
          </>
        ) : null}
      </Header>
      <Layout className="flex-grow">
        <Sider theme={"light"}>
          <Menu
            className="h-full"
            theme={"light"}
            selectedKeys={[menuActiveKey]}
            items={menuItems}
            onClick={onMenuClick}
          />
        </Sider>
        <Content className="bg-white">
          <Routes>
            <Route index element={<Navigate to={`org`} replace />} />
            <Route path={`org/*`} element={<OrgIndex />} />
            <Route path={`account/*`} element={<AccountIndex />} />
            <Route path={`location/*`} element={<LocationIndex />} />
            <Route path={`acl/*`} element={<AclIndex />} />
            <Route path={`user/*`} element={<UserIndex />} />
            <Route path={`educational-videos/*`} element={<EducationalVideosIndex />} />
            <Route path={`external-signup/*`} element={<ExternalSignupIndex />} />
            <Route path={`trial-domains/*`} element={<TrialDomainsIndex />} />
            <Route path={`incidents/*`} element={<IncidentsIndex />} />
            <Route path={`pricing-calculator-submissions/*`} element={<PricingCalculatorSubmissionsIndex />} />
            <Route path={`billing/*`} element={<BillingIndex />} />
            <Route path={`custom-logo/*`} element={<CustomLogoIndex />} />
          </Routes>
        </Content>
      </Layout>
      <Footer className="text-white" style={{ backgroundColor: colors.darkBlue }}>
        Control Plane
      </Footer>
    </Layout>
  );
};

export const AdminRoute = observer(AdminRouteRaw);
