import * as React from "react";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";
import { Button, Input, notification } from "antd";
import { useMain } from "./stores/main";
import { IS_TEST_ENVIRONMENT } from "./envVariables";
import { VersionCopyright } from "./Version";

const SignInRaw: React.FC = () => {
  const main = useMain();
  const [allSignInOptions, setAllSignInOptions] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  React.useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key == "q") {
        setAllSignInOptions((val) => !val);
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, []);

  async function tryAuthenticate(
    provider: "google" | "microsoft" | "github" | "email",
    email?: string,
    password?: string
  ) {
    try {
      await main.authenticate(provider, email, password);
    } catch (e) {
      notification.warning({
        message: "Failed to Sign In",
        description: e.message,
      });
    }
  }

  return (
    <>
      <Helmet>
        <title>
          {IS_TEST_ENVIRONMENT ? `TEST | ` : ""}Sign In - Admin Panel
        </title>
      </Helmet>
      <div className="flex w-full h-full items-stretch bg-white p-16 flex-col">
        <div className="w-full flex justify-center mb-16 mt-8">
          <a href={`https://controlplane.com`} target={"_blank"}>
            <img
              width={220}
              height={"auto"}
              src="/resources/logos/controlPlaneLogo.svg"
              alt="Control Plane Logo"
            />
          </a>
        </div>
        <div className="w-full flex justify-center mb-4 mt-2">
          <div className="text-xl font-bold">Sign In</div>
        </div>
        <div className="flex flex-col items-center w-full text-sm">
          {allSignInOptions ? (
            <>
              {IS_TEST_ENVIRONMENT ? (
                <>
                  <div style={{ width: 280 }} className="flex flex-col mb-4">
                    <Input
                      value={email}
                      type={"email"}
                      onChange={(e) => setEmail(e.target.value)}
                      className="mb-2"
                      autoFocus
                      placeholder={"Email"}
                    />
                    <Input
                      value={password}
                      type={"password"}
                      onChange={(e) => setPassword(e.target.value)}
                      className="mb-2"
                      placeholder={"Password"}
                    />
                    <Button
                      size={"large"}
                      onClick={() => {
                        tryAuthenticate("email", email, password);
                      }}
                      color={"blue"}
                    >
                      Sign In
                    </Button>
                  </div>
                  <button
                    onClick={() => tryAuthenticate("google")}
                    className={`bg-white mb-4 flex items-center pl-4`} //
                    style={{
                      width: 220,
                      height: 40,
                      boxShadow: `0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)`,
                    }} //
                  >
                    <span>
                      <img
                        width={18}
                        height={18}
                        src="/resources/logos/login/google.svg"
                      />
                    </span>
                    <span
                      className="pl-4"
                      style={{ fontWeight: 500, color: "#757575" }}
                    >
                      Continue with Google
                    </span>
                  </button>
                </>
              ) : null}
            </>
          ) : null}
          <button
            onClick={() => tryAuthenticate("microsoft")}
            className={`mb-4 flex items-center pl-4`} //
            style={{
              width: 220,
              height: 40,
              boxShadow: `0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)`,
              backgroundColor: "#2f2f2f",
            }} //
          >
            <span>
              <img
                width={18}
                height={18}
                src="/resources/logos/login/microsoft.svg"
              />
            </span>
            <span className="pl-4 text-white" style={{ fontWeight: 500 }}>
              Continue with Microsoft
            </span>
          </button>
        </div>
        <div className="flex-grow" />
        <div className="mt-16">
          <div className="text-gray-4 text-center">
            <VersionCopyright /> 2021 Control Plane. All rights reserved.
          </div>
        </div>
      </div>
    </>
  );
};

export const SignIn = observer(SignInRaw);
