import { Alert, Button, Form, Input, notification, Select, Switch } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { IncidentStore } from "../../stores/incident";
import {
  createIncident,
  fetchComponents,
  fetchIncident,
  fetchLocations,
  replaceIncident,
  updateComponentStatus,
} from "./http";
import {
  CPLNLocation,
  IncidentComponentStatus,
  IncidentEditable,
} from "./types";
import { isEqual } from "lodash";
import { RichTextEditor } from "../../RichTextEditor/richTextEditor";
import { Spinner } from "../../Components/Spinner";
import { DatePicker } from "../../Components/antd/DatePicker";
import moment from "moment-timezone";

type RouteParams = "component" | "incidentId";
const IncidentEditRaw: React.FC = () => {
  const navigate = useNavigate();

  const { component: _componentName, incidentId: _incidentId } =
    useParams<RouteParams>();
  const componentName = _componentName!;
  const incidentId = _incidentId!;

  const { pathname } = useLocation();
  const isNew = pathname.endsWith("/new/edit");

  const [locations, setLocations] = React.useState<CPLNLocation[]>([]);

  const [componentStatus, setComponentStatus] =
    React.useState<IncidentComponentStatus | null>(null);

  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState("");

  const [isDirty, setIsDirty] = React.useState(false);
  const [currentFormState, setCurrentFormState] =
    React.useState<IncidentEditable>(null as any);

  const [initialFormData, setInitialFormData] = React.useState<
    IncidentEditable | null | undefined
  >(null);
  const [form] = Form.useForm();

  React.useEffect(() => {
    process();
  }, []);

  async function process() {
    try {
      setIsLoading(true);

      const components = await fetchComponents();
      IncidentStore.setComponents(components);
      const component = IncidentStore.components.find(
        (c) => c.name === componentName
      );
      if (component) {
        setComponentStatus(component.status);
      }

      let incidentEditable: IncidentEditable = {} as any;
      if (isNew) {
        incidentEditable = {
          component: componentName,
          description: "",
          detectedTime: moment(),
          title: "",
          type: "maintenance",
          links: [],
          id: "",
          resolveComponent: false,
        };
      } else {
        const incidentItem = await fetchIncident(incidentId);
        incidentEditable = Object.assign(incidentItem, {
          detectedTime: moment(incidentItem.detectedTime),
          resolvedTime: incidentItem.resolvedTime
            ? moment(incidentItem.resolvedTime)
            : undefined,
          resolveComponent: false,
        }) as IncidentEditable;
      }

      setInitialFormData(incidentEditable);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setError(e.message);
    }

    try {
      const _locations = await fetchLocations();
      setLocations(_locations);
    } catch (e) {
      setLocations([]);
    }
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <div className={`flex flex-col gap-2 pl-8`}>
        <div>{error}</div>
        <Link to={`/admin/incidents/component/${componentName}`}>Go Back</Link>
      </div>
    );
  }

  async function onFinish() {
    try {
      setIsLoading(true);
      const formData = getFormState();

      let id = "";
      if (isNew) {
        id = (await createIncident(formData)).id;
      } else {
        id = (await replaceIncident(formData)).id;
      }

      if (formData.resolvedTime && formData.resolveComponent) {
        await updateComponentStatus(componentName, "available");
      }

      notification.success({ message: "Success" });
      setIsLoading(false);
      navigate(`/admin/incidents/component/${componentName}/incident/${id}`);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to save",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  function getFormState(): IncidentEditable {
    const formState = form.getFieldsValue([
      "component",
      "title",
      "description",
      "type",
      "locations",
      "detectedTime",
      "resolvedTime",
      "resolveComponent",
    ]);
    return Object.assign(formState, { id: incidentId || "new" });
  }

  function calculateIsDirty() {
    setIsDirty(!isEqual(getFormState(), initialFormData));
  }

  return (
    <div className="flex flex-col gap-4 pt-16">
      <div className={"ml-48 text-xl"}>
        {isNew ? "Create" : "Edit"} Incident
      </div>
      <div className={"pl-8 pt-8"}>
        <Form
          form={form}
          onValuesChange={() => {
            calculateIsDirty();
            setCurrentFormState(getFormState());
          }}
          name="incident"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          initialValues={initialFormData as any}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Component"
            name="component"
            rules={[{ required: true, message: "Component is required" }]}
          >
            <Select
              disabled
              options={IncidentStore.components.map((c) => ({
                value: c.name,
                label: c.description,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: "Title is required" }]}
          >
            <Input autoFocus />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: "Description is required" }]}
            valuePropName={"formValue"}
            trigger={"formOnChange"}
          >
            <RichTextEditor />
          </Form.Item>
          <Form.Item
            label="Type"
            name="type"
            rules={[{ required: true, message: "Type is required" }]}
          >
            {/* TODO make this radio button */}
            {/* TODO put icons with colors here */}
            <Select
              options={[
                { value: "disruption" },
                { value: "outage" },
                { value: "maintenance" },
              ]}
            />
          </Form.Item>
          <Form.Item name="locations" label="Locations">
            <Select
              mode={"multiple"}
              options={locations.map((l) => ({ value: l.name }))}
              placeholder={"Global"}
            />
          </Form.Item>
          <Form.Item
            name="detectedTime"
            label="Detected Time"
            rules={[{ required: true, message: "Detected Time is required" }]}
          >
            <DatePicker showTime />
          </Form.Item>
          <Form.Item name="resolvedTime" label="Resolved Time">
            <DatePicker showTime allowClear />
          </Form.Item>
          {componentStatus === "available" ? null : (
            <Form.Item
              name="resolveComponent"
              label="Resolve Component"
              extra={
                currentFormState?.resolveComponent === true &&
                !currentFormState?.resolvedTime ? (
                  <Alert
                    type={"warning"}
                    message={`Component won't be resolved if Resolved Time is not selected.`}
                  />
                ) : null
              }
            >
              <Switch disabled={!currentFormState?.resolvedTime} />
            </Form.Item>
          )}
          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <div className={"flex items-center gap-4"}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!isDirty || isLoading}
                loading={isLoading}
              >
                {incidentId === "new" ? "Add Incident" : "Save Changes"}
              </Button>
              <Button
                type="primary"
                danger
                disabled={isLoading}
                loading={isLoading}
                onClick={() => {
                  if (incidentId === "new") {
                    navigate(`/admin/incidents/component/${componentName}`);
                  } else {
                    navigate(
                      `/admin/incidents/component/${componentName}/incident/${incidentId}`
                    );
                  }
                }}
              >
                Cancel
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export const IncidentEdit = observer(IncidentEditRaw);
