import { observer } from "mobx-react-lite";
import { Table, Tag, Tooltip } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import * as React from "react";
import { IncidentStore } from "../../stores/incident";
import { fetchComponents, fetchIncidents } from "./http";
import { Incident, IncidentComponent } from "./types";
import { Link } from "react-router-dom";
import { IconsWithLabel } from "./icons";

const ComponentListRaw: React.FC = () => {
  React.useEffect(() => {
    process();
    return () => {
      IncidentStore.setIncidents([]);
    };
  }, []);

  async function process() {
    try {
      const components = await fetchComponents();
      IncidentStore.setComponents(components);
      const incidents = await fetchIncidents({ daysBack: 7 });
      IncidentStore.setIncidents(incidents);
    } catch (e) {
      IncidentStore.setIncidents([]);
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <PageHeader title={"Components"} backIcon={false} />
      <div className="px-8 pb-4">
        <Table
          rowKey={"name"}
          pagination={false}
          size={"small"}
          columns={[
            {
              key: "description",
              dataIndex: "description",
              title: "Component",
              render: (_: string, record: IncidentComponent) => {
                return (
                  <Link
                    className={"text-blue-500 hover:text-blue-600"}
                    to={`component/${record.name}`}
                  >
                    {record.description}
                  </Link>
                );
              },
            },
            {
              key: "location",
              dataIndex: "locations",
              title: "Locations",
              render: (_: string, record: IncidentComponent) => {
                if (!record.locations || record.locations.length < 1) {
                  return <div>Global</div>;
                }

                const remaining = record.locations.length - 3;

                return (
                  <div className={"flex items-center gap-2"}>
                    {record.locations.slice(0, 3).map((l) => (
                      <Tag key={record.name + l}>{l}</Tag>
                    ))}
                    {remaining > 0 ? (
                      <Tooltip
                        className={"text-blue-500"}
                        title={
                          <div>
                            {record.locations.slice(3).map((l) => (
                              <div key={record.name + l} className={"my-1"}>
                                <Tag>{l}</Tag>
                              </div>
                            ))}
                          </div>
                        }
                      >
                        +{remaining}
                      </Tooltip>
                    ) : null}
                  </div>
                );
              },
            },
            {
              key: "status",
              dataIndex: "status",
              title: "Status",
              render: (_: string, record: IncidentComponent) => {
                const IconComponent = IconsWithLabel[record.status];
                return <IconComponent />;
              },
            },
          ]}
          dataSource={IncidentStore.components}
        />
      </div>
      <div className={"text-xl pl-8"}>{"Incidents from Last 7 Days"}</div>
      <div className={`flex flex-col pl-8`}>
        <Table
          pagination={false}
          size={"small"}
          columns={[
            {
              key: "title",
              dataIndex: "title",
              title: "Title",
              render: (title: string, incident: Incident) => (
                <Link
                  className={"text-blue-500 hover:text-blue-600"}
                  to={`component/${incident.component}/incident/${incident.id}`}
                >
                  {title}
                </Link>
              ),
            },
            {
              key: "component",
              dataIndex: "component",
              title: "Component",
              render: (component: string) => (
                <div>
                  {
                    IncidentStore.components.find((c) => c.name === component)
                      ?.description
                  }
                </div>
              ),
            },
            {
              key: "type",
              dataIndex: "type",
              title: "Type",
              render: (_: string, incident: Incident) => {
                const IconComponent = IconsWithLabel[incident.type];
                return <IconComponent />;
              },
            },
            {
              key: "detectedTime",
              dataIndex: "detectedTime",
              title: "Detected Time",
              render: (_: string, incident: Incident) => {
                return <div>{incident.detectedTime.toISOString()}</div>;
              },
            },
            {
              key: "resolvedTime",
              dataIndex: "resolvedTime",
              title: "Resolved Time",
              render: (_: string, incident: Incident) => {
                return (
                  <div>
                    {incident.resolvedTime?.toISOString() || "Not Resolved"}
                  </div>
                );
              },
            },
          ]}
          dataSource={IncidentStore.incidents}
        />
      </div>
    </div>
  );
};

export const ComponentList = observer(ComponentListRaw);
